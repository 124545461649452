/* src/components/PaymentStyles.css */
.result-container {
    text-align: center;
    padding: 40px 20px;
    margin: 20px auto;
    background: #ffffff;
    border-radius: 8px;
    width: 90%;
    max-width: 400px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .result-icon {
    font-size: 64px;
    margin-bottom: 20px;
  }
  
  .success .checkmark {
    color: #4caf50;
  }
  
  .error .crossmark {
    color: #f44336;
  }
  
  h1 {
    margin: 10px 0;
    font-size: 24px;
  }
  
  p {
    color: #666;
    margin-bottom: 20px;
  }
  
  .action-button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
  }
  
  .action-button:hover {
    background-color: #0056b3;
  }
  