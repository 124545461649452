.category-screen {
    width: 50rem;
    height: 25rem;
    margin-left: auto;
    margin-right: auto;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 3rem;
    margin-top: 4rem;
}

.category-title {
    color: grey;
    text-align: center;
}

.all-products {
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 5rem;
    padding: 4rem;
    border-radius: 1rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}


.product-icon{
    padding : 20px 40px;
    border-radius: 4px;
    border: 0.5px solid #ececee;
}


.product-item {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.product-item :hover {
    cursor: pointer;
    
}



.product-icon:hover {
    border: 1px solid blue;
    background-color: #ececee;
}

.product-title {
    font-weight: bold;
    margin-top: 6px;
}

.background-content-category{
    display: flex;
    flex-direction: column;
    gap: 3rem;
    /* margin-top: 2rem; */
}