.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal-content {
    background: #fff;
    border-radius: 10px;
    padding: 20px;
    text-align: center;
    position: relative;
  }
  
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 24px;
    background: none;
    border: none;
    cursor: pointer;
  }
  
  .qr-code {
    width: 200px;
    height: 200px;
    margin: 20px 0;
  }
  
  .payable-amount {
    background-color: #f0f4f0;
    padding: 10px;
    border-radius: 8px;
    width: 100%;
  }
  
  .payable-amount p {
    margin: 0;
    color: #666;
    font-size: 14px;
  }
  
  .payable-amount h3 {
    margin: 5px 0 0;
    color: #333;
    font-size: 20px;
  }
  

  .loading-Qr p{
    margin-bottom : 10px;
    color: #666;

  }