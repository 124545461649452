.side-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: flex-end; /* This will push the modal to the right */
  align-items: stretch; /* This ensures the modal stretches to full height */
  z-index: 1000;
}

.side-modal {
  background: #fff;
  width: 500px;
  max-width: 100%;
  height: 100%; /* Full height */
  padding: 20px;
  box-shadow: -2px 0 10px rgba(0, 0, 0, 0.2);
  position: relative;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  background: none;
  border: none;
  cursor: pointer;
}

.loan-details-sidePop {
  padding: 20px;
  margin-top: 40px;
  background: #f7f7f7;
}

.loan-details p {
  margin: 8px 0;
  color: #333;
}

.principal-amount {
  background-color: #f0f4f0;
  padding: 10px;
  border-radius: 8px;
  margin-top: 20px;
}

.cancel-button {
  display: block;
  position: absolute;
  bottom: 20px; /* Distance from the bottom */
  left: 0;
  right: 0;
  margin: 0 auto;
  padding: 10px;
  width: calc(100% - 40px); /* Optional: Adjust width */
  background: none;
  border: 1px solid #3248d4;
  border-radius: 5px;
  color: #333;
  cursor: pointer;
  text-align: center;
  color: blue;
}

.loan-details-field {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
}

.brakeup-field {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
}


.loan-details-field-value{
  font-weight: 550;
  color: rgb(0, 0, 0);
}


.breakup-field-value{
  font-weight: 550;
  color: rgb(0, 0, 0);
}


.loan-details-field-title{
  font-weight: 450;
}

.breakup-field-title{
  font-weight: 450;
}