/* Container */
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  /* background-color: #f8f9fa; */
  min-height: 100vh;
  width: 100%;
}

/* Card */
.card {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-bottom: 20px;
  width: 700px;
}

/* Borrower Info */
.borrower-info {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  width: 700px;
  padding: 20px;
  border-radius: 8px;
}

.avatar {
  background-color: #007bff;
  color: #fff;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: bold;
  margin-right: 15px;
}

.borrower-text {
  display: flex;
  flex-direction: column;
}

.borrower-id {
  color: #888;
  font-size: 14px;
  margin: 0;
  margin-bottom: 10px;
}

.borrower-name {
  font-size: 20px;
  font-weight: bold;
  margin: 0;
  color: #2b2d2f;
}

/* Loan Details */
.loan-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.loan-title {
  font-size: 16px;
  color: #555;
  padding-bottom: 4px;
}

.amount {
  font-size: 24px;
  font-weight: bold;
  margin: 0;
  color: #2b2d2f;
}

/* Due Info */
.due-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  font-size: 14px;
  color: #666;
}

.due-date {
  font-weight: bold;
}

.overdue {
  color: #ff0000;
  font-weight: bold;
}

/* Buttons */
.buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}

/* .view-breakup,
.repay-now {
  padding: 16px 90px;
  font-size: 16px;
  cursor: pointer;
  outline: none;
  border-radius: 25px;
  border: 1px solid transparent;
} */

/* .view-breakup {
  background-color: #ffffff;
  border: 1px solid #2b2d2f;
  color: #2b2d2f;
} */

.repay-now {
  background-color: #2b2d2f;
  color: #fff;
}

.repay-now:disabled {
  background-color: #ccc; /* Faded background for disabled state */
  color: white; /* Faded black text color */
  cursor: not-allowed; /* Remove pointer effect */
}

.view-breakup,
.repay-now {
  padding: 16px 90px;
  font-size: 16px;
  cursor: pointer;
  outline: none;
  border-radius: 25px;
  border: 1px solid transparent;
}

.view-breakup {
  background-color: #ffffff;
  border: 1px solid #2b2d2f;
  color: #2b2d2f;
}

/* Disabled state styling for View Breakup only */
.view-breakup:disabled {
  background-color: #f0f0f0;
  border-color: #d1d1d1;
  color: #9b9b9b;
  cursor: not-allowed;
}

/* Upcoming Dues */
.upcoming-dues {
  background-color: #ffffff;
  border-radius: 8px;
  /* box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); */
  /* padding: 20px; */
  width: 100%;
  max-width: 700px;
  margin-top: 20px;
}

.upcoming-dues h3 {
  margin: 0 0 15px;
  font-size: 18px;
  font-weight: bold;
  color: #2b2d2f;
}

.due-item {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  border-top: 1px solid #ddd;
  font-size: 14px;
}

.due-item:first-child {
  border-top: none;
}

.back-button {
  display: inline-block;
  padding: 6px 10px;
  font-size: 16px;
  color: #ffffff;
  background-color: #007bff; /* Blue color */
  border: none;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.back-button:hover {
  background-color: #0056b3; /* Darker blue on hover */
  transform: scale(1.05); /* Slight zoom effect */
}

.back-button:active {
  background-color: #003d80; /* Even darker blue on click */
  transform: scale(0.98); /* Slightly smaller on click */
}

.view-details {
  /* padding: 16px 40px; */
  font-size: 16px;
  cursor: pointer;
  border-radius: 25px;
  border: 1px solid #2b2d2f;
  background-color: #ffffff;
  color: #007bff;
  outline: none;
  border: none;
  font-weight: bold;
}

.view-details.disabled,
.view-details:disabled {
  border-color: #d1d1d1;
  color: #9b9b9b;
  cursor: not-allowed;
}

.button-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 87%;
  margin: auto;
}

