/* Download icon */
.download-icon {
    font-size: 3rem;
    color: #007bff;
}

/* Button styling */
.download-btn {
    width: 16rem;
    height: 3rem;
    padding: 10px 20px;
    background-color: white;
    color: #007bff;
    border: 1.5px solid #007bff;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
}

/* Button hover effect */
.download-btn:hover {
    background-color: #007bff;
    color: white;
}

/* Close button */
.cross {
    color: grey;
    position: absolute;
    top: 1rem;
    right: 1rem;
    font-size: 1.5rem;
    font-weight: bold;
    cursor: pointer;
}

/* Text Styling */
.pdf-statement,
.message-1,
.message-2 {
    color: grey;
    font-size: 17px;
    text-align: center;
}

.message-1 {
    font-weight: bold;
}
