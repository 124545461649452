.download-pdf-screen {
    background-color: white;
    border-radius: 1rem;
    padding: 3rem;
    width: 30rem;
    height: 20rem;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 3rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}



.download-icon {
    transform: scale(1);
    color: #007bff;
}

.download-btn {
    width: 16rem;
    height: 3rem;
    padding: 10px 20px;
    background-color: white;
    color: #007bff;
    border: 1.5px solid #007bff;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.download-btn:hover {
    background-color: #007bff;
    color: white;
}

.cross {
    color: grey;
    position: absolute;
    top: 1rem;
    right: 1rem;
    font-size: x-large;
    font-weight: bolder;
}

.cross:hover {
    cursor: pointer;
}

.pdf-statement {
    color: grey;
    font-size: 17px;
    text-align: center;
}

.message-1{
    font-weight: bolder;
    font-size: 17px;
    margin-bottom: 10px;
    color: grey;

}
.message-2{
    font-size: 17px;
    color: grey;

}