.button-group {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    padding-left: 2rem;
    padding-right: 2rem;
}

.title {
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: 0px;
    text-align: center;
}

.cancel-btn {
    width: 16rem;
    height: 3rem;
    padding: 10px 20px;
    background-color: white;
    color: #007bff;
    border: 1.5px solid #007bff;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.cancel-btn:hover {
    background-color: #007bff;
    color: white;
}

.submit-btn {
    width: 16rem;
    height: 3rem;
    padding: 10px 20px;
    background-color: #d3d3d3;
    color: white;
    border: none;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 500;
    cursor: not-allowed;
    opacity: 0.7;
}

.submit-btn-clickable {
    background-color: #007bff;
    color: white;
    border: 1.5px solid #007bff;
    opacity: 1;
    cursor: pointer;
}

.submit-btn-clickable:hover {
    background-color: #55a2f5;
    color: white;
}

.input-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.3rem;
}

.input-box {
    font-size: 20px;
    padding: 15px;
    width: 60px;
    height: 60px;
    border-radius: 8px;
    background: linear-gradient(0deg, #FFFFFF, #FFFFFF);
    border: 1px solid #CCCDD3;
    margin: 0 0.5em;
    text-align: center;
}

.resend-container {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: center;
    margin-top: 48px;
}

.resend-text-temp {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: center;
    color: #D3D3D3;
    cursor: not-allowed;
}

.resend-text {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: center;
    color: #134CDE;
    cursor: pointer;
}

.otpInputPopUp-first {
    height: 38%;
    width: 35%;
    border-radius: 10px;
    position: absolute;
    background-color: white;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding-top: 2rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.hr {
    height: 1px;
    background-color: rgb(210, 210, 210);
    width: 100%;
}

.processing-Otp{
    display: "flex";
  align-items: "center";
  justify-content: "center";
}