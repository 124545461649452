.home-screen>div {
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
}

.submitform {
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5rem;
  padding: 2rem;
}

.submitform>h1 {
  color: grey;
}


.selection-heading {
  color: grey;
}


.disabled {
  opacity: 0.4;
  pointer-events: none;
}

.main-container {
  position: relative;
}

.main-container-1 {
  position: relative;
}

.background-content {
  background: white;
  padding: 50px;
  text-align: center;
}

.background-content-1 {
  background-color: white;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 3rem;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-overlay-1 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}


.modal-content {
  background: white;
  padding: 3rem;
  width: 30rem;
  border-radius: 10px;
  text-align: center;
  position: relative;
  z-index: 1100;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
}

.modal-content-1 {
  background: white;
  padding: 3rem;
  border-radius: 10px;
  text-align: center;
  position: relative;
  z-index: 1100;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
}

.home-page-title{
  color: rgb(88, 86, 86);
}