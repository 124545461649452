.header-section {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}

.header-image {
  transform: scale(1.2);
  margin-left: auto;
  margin-right: auto;
}

.header {
  margin-left: auto;
  margin-right: auto;
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.5rem;
}

.ruler {
  width: 100%;
  height: 1px;
  background-color: rgb(210, 208, 208);
}

.user {
  position: absolute;
  top: 36px;
  right: 30px; 
  height: 40px;
  width: auto;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.user-icon {
  display: flex;
  align-items: center;
  gap: 10px; 
  
}

.user-icon-img {
  width: 40px;
  height: 40px;
  border-radius: 50%;

}

.dropdown-arrow {
  font-size: 16px;
  color: #000; 
  transition: none; 
}


.user-icon:hover .dropdown-arrow {
  transform: none; 
}

.dropdown-menu {
  position: absolute;
  top: 48px;
  right: 0;
  background-color: white;
  border: none;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  padding: 10px;
  z-index: 10;
  min-width: 100%; 
}

.logout-button {
  background-color: transparent; 
  padding: 12px 75px 10px 2px; 
  cursor: pointer;
  text-align: left;
  font-size: 16px;
  font-weight: 450;
  width: 100%; 
  border: 2px solid white;
  border-radius: 8px;
   
   
}

.logout-button:hover {
  background: #FFFFFF; 
  
  

  
}
